import React, { useRef, useEffect, useState, useContext, useCallback } from 'react';

import { useHistory } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Chip } from 'primereact/chip';
import { DialogPerfis } from './DialogPerfis';
import { DialogHistoricoPerfil } from './DialogHistoricoPerfil';
import { DialogCertificadoPerfil } from './DialogCertificadoPerfil';
import { Dropdown } from 'primereact/dropdown';
import '../layout/sass/_pickListSugestoes.scss'
import '../layout/sass/_responsiveTable.scss'
import api from '../api/api';

import {FilterContext} from '../contexts/FilterContext';

export const TabelaPerfis = () => {
    const toast = useRef(null);
    const [perfisTotal, setPerfisTotal] = useState([]);
    const [perfis, setPerfis] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const confirmationToast = useRef(null)
    const [dt, setDt] = useState(null);
    const [showHistorico, setShowHistorico] = useState(false); 
    const [perfil, setPerfil] = useState(null);
    const [showCertificado, setShowCertificado] = useState(false); 
    const [perfilConcluido, setPerfilConcluido] = useState("");
    const [showNaoConcluido, setShowNaoConcluido] = useState(false);
    const history = useHistory();
    const [showListaPerfis, setShowListaPerfis] = useState(false);   
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedDeficiencia, setSelectedDeficiencia] = useState(null);
    const [sortable, setSortable] = useState('ASC');
    const [sortableCampo, setSortableCampo] = useState('id');
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tipos, setTipos] = useState([]);
    const filterStatus = renderFilterStatus();
    const filterDeficiencia = renderFilterDeficiencia();
    const { filter, setFilter } = useContext(FilterContext);

    const header = (
        <div className="p-grid p-col-12 p-m-0 p-p-0">
            <div>
                <h2 className="p-my-0">Perfis</h2>
            </div>
            <div className="p-my-0 p-grid p-dir-rev p-col">
                <div className="p-m-2">
                <Button
                    label="Adicionar"
                    className="p-mb-2 p-button-primary"
                    icon="pi pi-plus"
                    iconPos="left"
                    type="button"

                    onClick={() => history.push('/perfiscreate')}

                />
                </div>
                <div className="p-m-2">
                    <Button
                        label="Limpar Filtros"
                        className="p-mb-2 p-button-primary"
                        iconPos="left"
                        type="button"
                        onClick={() => handleLimparClick()}
                    />
                </div>
            </div>
        </div>
        
    );

    const handleLimparClick = () => {
        setFilter({
            first: 0,
            rows: 20,
            sortField: null,
            sortOrder: null,
            filters: {
            },
            
        });
        setSelectedStatus(null);
        setSelectedDeficiencia(null);
    }

    
    const getPerfis = useCallback(async (event) => {
        setIsLoading(true)
        setPerfis([])

        // console.log(event)   

        if(event !== undefined) {
            setTimeout(() => {
                const startIndex = event.first;
                setFirst(startIndex);
                // setPerfis(dataSource.slice(startIndex, endIndex));            
            }, 250);
        }
       
        try {

            if(filter.filters === undefined) 
            {

                if(event === undefined) {
                    const response = await api.get(`perfil?sort=${sortableCampo},${sortable}`);
                    // console.log(response.data.content);
                    response.data.content.forEach(perfil => {                        
                        setPerfis(perfis => [...perfis, perfil])
                    })                
                } else {
                    const response = await api.get(`perfil?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    // console.log(response.data.content);
                    response.data.content.forEach(perfil => {
                        setPerfis(perfis => [...perfis, perfil])
                    })  
                    
                }

                const respElements = await api.get(`perfil?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);

            } 
            else 
            { 
                const pesquisa = {
                    "id": filter.filters['id'] !== undefined ? filter.filters['id'].value : null,
                    "nome": filter.filters['nome'] !== undefined ? filter.filters['nome'].value : "",
                    "cpf": filter.filters['cpf'] !== undefined ? desformat(filter.filters['cpf'].value) : "",
                    "email": filter.filters['email'] !== undefined ? filter.filters['email'].value : "",
                    "celular": filter.filters['celular'] !== undefined ? filter.filters['celular'].value : "",
                    "tipo": filter.filters['tipo'] !== undefined ? filter.filters['tipo'].value : null,
                    "deficiencia": filter.filters['deficiencia'] !== undefined ? filter.filters['deficiencia'].value : null,
                }

                if (event === undefined) {   
                    const respElements = await api.post(`perfil/searchPerfil/${sortable}/${sortableCampo}?`, pesquisa);
                    respElements.data.content.forEach(perfil => {
                        setPerfis(perfis => [...perfis, perfil])
                    })
                    setTotalRecords(respElements.data.totalElements);
                } else {
                    const respElements = await api.post(`perfil/searchPerfil/${sortable}/${sortableCampo}?page=${event.page}`, pesquisa);
                    respElements.data.content.forEach(perfil => {
                        setPerfis(perfis => [...perfis, perfil])
                    })
                    setTotalRecords(respElements.data.totalElements);
                }
            } 
            
        }
        catch (error) {  

            if(filter !== undefined){
                if(event === undefined) {
                    const response = await api.get(`perfil?sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(perfil => {
                        setPerfis(perfis => [...perfis, perfil])
                    })                  
                } else {
                    const response = await api.get(`perfil?page=${event.page}&sort=${sortableCampo},${sortable}`);
                    response.data.content.forEach(perfil => {
                        setPerfis(perfis => [...perfis, perfil])
                    })  
                    
                }

                const respElements = await api.get(`perfil?sort=${sortableCampo},${sortable}`);
                setTotalRecords(respElements.data.totalElements);
            } else {
                toast.current.show({ severity: 'error', summary: 'Erro ao obter perfis', detail: error.toString() });    
            }
              
        }

        setIsLoading(false)
    }, [filter,sortable,sortableCampo]);

    useEffect(() => {
        const fetchPerfis = async () => {
            try {
                await getPerfis();

            }
            catch (error){
                toast.current.show({ severity: 'error', summary: 'Erro ', detail: error.toString() });
            }
        }
        fetchPerfis();

    }, [getPerfis])

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-print" tooltip='Histórico' className="p-button-rounded p-mr-2 edit-btn" onClick={() => handleHistoricoClick(rowData)}/>
                <Button icon="pi pi-print" tooltip='Certificado' className="p-button-rounded p-mr-2 edit-btn" onClick={() => handleCertificadoClick(rowData)}/>
                <Button icon="pi pi-pencil" className="p-button-rounded p-mr-2 edit-btn" onClick={() => handleEditClick(rowData)}/>                
            
            </div>
        );
    }

    function renderFilterStatus() {
        return (
            <Dropdown
                value={selectedStatus}
                options={tipos}
                onChange={(e) => onStatusSearchChange(e)}
                placeholder="Selecione um status"
                className="p-column-filter"
                showClear={true} />
        );
    }

    function renderFilterDeficiencia() {
        return (
            <Dropdown
                value={selectedDeficiencia}
                options={['NENHUM', 'DEFICIÊNCIA AUDITIVA', 'DEFICIÊNCIA VISUAL', 'DEFICIÊNCIA INTELECTUAL', 'DEFICIÊNCIA MOTORA', 'AUTISMO', 'TDAH', 'SINDROME DE DOWN', 'OUTROS']}
                onChange={(e) => onDeficienciaSearchChange(e)}
                placeholder="Selecione um status"
                className="p-column-filter"
                showClear={true} />
        );
    }

    function desformat(data) {
        return data.replace(/[\D]/g, "");
      }
    
    function formatCpf(data) {

        const cpf = data != null ? data.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4") : data;

        return cpf;
    }

    const codBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Código</span>
                    <span className="table-data">{rowData.id}</span>
                </p>
            </React.Fragment>
        );
    }

    const nomeBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Nome</span>
                    <span className="table-data">{rowData.nome}</span>
                </p>
            </React.Fragment>
        );
    }

    const cpfBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">CPF</span>
                    {rowData.cpf === ""
                        ? <span className="table-data">NÃO INFORMADO</span>
                        : <span className="table-data">{formatCpf(rowData.cpf)}</span>}
                </p>
            </React.Fragment>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Email</span>
                    {rowData.email === ""
                        ? <span className="table-data">NÃO INFORMADO</span>
                        : <span className="table-data">{rowData.email}</span>}
                </p>
            </React.Fragment>
        );
    }

    const celularBodyTemplate = (rowData) => {
        return(
            <React.Fragment>
                <p style={{textalign: 'left'}}>
                    <span className="p-column-title">Celular</span>
                    {rowData.celular === ""
                        ? <span className="table-data">NÃO INFORMADO</span>
                        : <span className="table-data">{rowData.celular}</span>}
                </p>
            </React.Fragment>
        );
    }

    const tipoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Tipo</span>
                <span className="table-data"><Chip icon={`pi pi-`} style={{color: 'white', backgroundColor: rowData.tipo === 'INSTRUTOR' ? '#228B22' : rowData.tipo === 'ADMIN' ? '#2196F3' : '#FFA500'}} label={rowData.tipo}></Chip></span>
            </React.Fragment>
        );
    }

    const deficienciaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Deficiência</span>
                <span className="table-data"><Chip icon={`pi pi-`} style={{color: 'white', backgroundColor: rowData.deficiencia === 'NENHUM' ? '#228B22' : '#FFA500'}} label={rowData.deficiencia  === "deficienciaAuditiva" ? "DEFICIÊNCIA AUDITIVA"
                                                    : rowData.deficiencia === "deficienciaVisual" ? "DEFICIÊNCIA VISUAL"
                                                    : rowData.deficiencia === "deficienciaIntelectual" ? "DEFICIÊNCIA INTELECTUAL"
                                                    : rowData.deficiencia === "deficienciaMotora" ? "DEFICIÊNCIA MOTORA"
                                                    : rowData.deficiencia === "sindromeDeDown" ? "SINDROME DE DOWN"
                                                    : rowData.deficiencia}></Chip></span>
            </React.Fragment>
        );
    }

    const onSort = (event) => {
        setSortableCampo(event.sortField);
        if(sortable === 'ASC'){
            setSortable('DESC')
        } else if (sortable === 'DESC') {
            setSortable('ASC')
        }
    }

    const onFilter = (event) => {
        event['first'] = 0;
        // setLazyParams(event);   
        setFilter(event);
    }

    const handleEditClick = (data) => {

        history.push(`/perfis/edit/${data.id}`);

    }
    
    const handleHistoricoClick = async (data) => {
        const historico = await api.get(`matricula/perfil/${data.id}`);
        if (historico.data !== "" && historico.data != null)
        {
            setShowHistorico(true);
            setPerfil(historico.data);
        }
    }

    const handleCertificadoClick = async (data) => {
        const certificado = await api.get(`perfil/concluido/${data.id}`);

        if (certificado.data.length !== 0)
        {
            setShowCertificado(true);
            setPerfilConcluido(certificado.data);
        }
        else
        {
            setShowNaoConcluido(true);
        }
    }

    const handlePrintClick = () => {
        setShowListaPerfis(true);
    }

    const onStatusSearchChange = (event) => {
        if (event.value !== null){
            dt.filter(event.value, 'tipo', 'equals');
            setSelectedStatus(event.value);
        }
        else
            dt.filter(null, 'tipo', 'equals');
    }
    
    const onDeficienciaSearchChange = (event) => {
        if (event.value !== null){
            dt.filter(event.value === "DEFICIÊNCIA AUDITIVA" ? "deficienciaAuditiva"
            : event.value === "DEFICIÊNCIA VISUAL" ? "deficienciaVisual"
            : event.value === "DEFICIÊNCIA INTELECTUAL" ? "deficienciaIntelectual"
            : event.value === "DEFICIÊNCIA MOTORA" ? "deficienciaMotora"
            : event.value === "SINDROME DE DOWN" ? "sindromeDeDown"
            : event.value , 'deficiencia', 'equals');
            setSelectedDeficiencia(event.value);
        }
        else
            dt.filter(null, 'deficiencia', 'equals');
    }

    const getPerfisAll = useCallback(async () => {
        try {
            const response = await api.get(`perfil/perfilAll`);
            setPerfisTotal(response.data)
        }
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Erro ao obter perfis', detail: error.toString() });
        }
    }, []);

    const getTipos = useCallback(async () => {
        const response = await api.get(`tipoPerfil`);
        setTipos(response.data.map(tipos => {
            var Tipos = { label: '', value: '' };
            Tipos.label = tipos
            Tipos.value = tipos

            return Tipos
        }))
        filter.filters !== undefined && (filter.filters.tipo !== undefined && filter.filters.tipo.value != null) ? setSelectedStatus(filter.filters.tipo.value) : setSelectedStatus(null)
        filter.filters !== undefined && (filter.filters.deficiencia !== undefined && filter.filters.deficiencia.value != null) ? setSelectedDeficiencia(filter.filters.deficiencia.value) : setSelectedDeficiencia(null)    
    }, [filter]);

    useEffect(() => {

        async function perfis() {
            await getPerfisAll();
            await getTipos();
        };
        perfis();
    }, [getPerfisAll,getTipos])

    return (
        <>
        <Dialog
                visible={showNaoConcluido}
                header={"Atenção!"}
                onHide={() => setShowNaoConcluido(false)}
                closable={false}
                icon="pi pi-exclamation-triangle"
                breakpoints={{ '960px': '75vw', '640px': '90vw' }}
            >
                <div className="p-d-flex p-flex-row p-ai-center">
                    <i className="pi pi-exclamation-triangle p-mr-4" style={{ 'fontSize': '2.5em' }} />
                    <span style={{ textAlign: 'justify' }}>
                        Nenhuma matrícula concluída!
                    </span>
                </div>
                <Button style={{ float: 'right', marginTop: '12px' }}
                    type="button"
                    label="Ok"
                    onClick={() => setShowNaoConcluido(false)} />
            </Dialog>
        <div className="card p-grid p-col-12 p-mx-0 p-p-0 p-mt-0">


         <DialogPerfis
                visible={showListaPerfis}
                setVisible={setShowListaPerfis}
                perfil={perfisTotal}
            />
        {perfil != null
            ?
            <DialogHistoricoPerfil
                visible={showHistorico}
                setVisible={setShowHistorico}
                perfil={perfil}
            />
            :
            null
        }
        {perfilConcluido !== ""
            ?
            <DialogCertificadoPerfil
                visible={showCertificado}
                setVisible={setShowCertificado}
                perfil={perfilConcluido}
            />
            :
            null
        }
            <div className="p-my-0 p-dir-rev p-col">
                <div className="p-my-0">
                <Button 
                    textalign="center"
                    icon="pi pi-print"
                    className="p-button-secondary"
                    style={{ width: '35px' }}
                    onClick={handlePrintClick}
                    tooltip="Gerar relatório"
                    tooltipOptions={{ position: 'top' }}
    /> 
                </div>
            </div>
           
           
      
            <Toast ref={toast} position="bottom-right" />
            
            <Toast ref={confirmationToast} position="bottom-center" />
            
            <div className="card p-col-12 datatable-responsive-demo">
                
                <div className="p-fluid">
                    
                <DataTable style={{ width: '100%' }}      
                    totalRecords={totalRecords}
                    lazy="true"
                    first={first}
                    onPage={getPerfis}
                    ref={(element) => setDt(element)}
                    header={header}
                    value={perfis}
                    filterDisplay="row"
                    className="p-datatable-responsive-demo"
                    emptyMessage="Nenhuma perfil encontrado"
                    loading={isLoading}
                    dataKey="id"
                    paginator
                    rows={20}
                    currentPageReportTemplate={`Mostrando de {first} a {last} de ${totalRecords} perfis`}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                    // rowsPerPageOptions={[10, 20, 30]} 
                    onSort={onSort}
                    onFilter={onFilter}
                    filters={filter.filters}
                >

                    <Column style={{ width: '10%' }}
                        field="id"
                        header="Código"
                        sortable={sortable}
                        body={codBodyTemplate}                            
                        filter
                        filterMatchMode="custom"
                        filterPlaceholder={filter.filters !== undefined && (filter.filters.id !== undefined && filter.filters.id.value != null) ? filter.filters.id.value : "Pesquisar pelo Código"}
                        
                    />
                    
                    <Column style={{ width: '20%' }}
                        field="nome"
                        header="Nome"
                        sortable={sortable}
                        body={nomeBodyTemplate}                            
                        filter
                        filterMatchMode="contains"
                        filterPlaceholder={filter.filters !== undefined && (filter.filters.nome !== undefined && filter.filters.nome.value !== "") ? filter.filters.nome.value : "Pesquisar pelo nome"}

                    />


                    <Column style={{ width: '12.5%' }}
                        field="cpf"
                        header="CPF"
                        sortable={sortable}
                        body={cpfBodyTemplate}                            
                        filter
                        filterMatchMode="contains"
                        filterPlaceholder={filter.filters !== undefined && (filter.filters.cpf !== undefined && filter.filters.cpf.value !== "") ? filter.filters.cpf.value : "Pesquisar pelo nome"}
                        
                    />


                    <Column style={{ width: '20%' }}
                        field="email"
                        header="Email"
                        sortable={sortable}
                        body={emailBodyTemplate}                            
                        filter
                        filterMatchMode="contains"
                        filterPlaceholder={filter.filters !== undefined && (filter.filters.email !== undefined && filter.filters.email.value !== "") ? filter.filters.email.value : "Pesquisar pelo nome"}
                        
                    />


                    <Column style={{ width: '20%' }}
                        field="celular"
                        header="Celular"
                        sortable={sortable}
                        body={celularBodyTemplate}                            
                        filter
                        filterMatchMode="contains"
                        filterPlaceholder={filter.filters !== undefined && (filter.filters.celular !== undefined && filter.filters.celular.value !== "") ? filter.filters.celular.value : "Pesquisar pelo nome"}
                        
                    />

                    <Column style={{ width: '14%' }}
                        field="tipo"
                        header="Tipo de Perfil"
                        sortable
                        body={tipoBodyTemplate}
                        filter
                        filterMatchMode="equals"
                        filterElement={filterStatus}

                    />

                    <Column style={{ width: '14%' }}
                        field="deficiencia"
                        header="Deficiência"
                        sortable
                        body={deficienciaBodyTemplate}
                        filter
                        filterMatchMode="equals"
                        filterElement={filterDeficiencia}

                    />

                    <Column style={{ width: '13.5%' }}
                        header="Ações"
                        body={actionBodyTemplate}
                        
                    />
    
                    </DataTable>
                    
                </div>
            </div>
        </div>
       
        </>
    );
}