import React, { useState, useContext } from 'react';

import { AuthContext } from './contexts/AuthContext';

import { PermissaoUsuario } from './components/PermissaoUsuario';

import './layout/layout.scss';

export const AppProfile = () => {

    const { user, signOut } = useContext(AuthContext);

    // const [expanded, setExpanded] = useState(false);
    const [showDialogPermissao, setShowDialogPermissao] = useState(false);

    const onClick = () => {
        if (user.perfilUser.includes('admin') && user.perfilUser.includes('user')) {
           setShowDialogPermissao(true);
        }
    }

    return (
        <div className="layout-profile">
            <PermissaoUsuario
                visible={showDialogPermissao}
                setVisible={setShowDialogPermissao}
                permissao="Administrador"
            />
            <div>
                <button className="p-link layout-profile-link" onClick={onClick}>
                    <span className="username">{user.nome.toUpperCase()}</span>
                    {/* <i className="pi pi-fw pi-cog" /> */}
                </button>
            </div>
            {/* <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={expanded} unmountOnExit>
                <div className="p-my-2 p-px-2" style={{ textAlign: 'left' }}>
                    <label className="p-ml-1 label-perfil-user">Perfil:</label>
                    <div className="p-d-flex p-jc-center p-px-1 p-mt-1">
                        <Dropdown value={selectedPerfil} options={perfis} optionLabel="label" onChange={handlePermissionSelection} className="perfil-user-dropdown" />
                    </div>
                </div>
            </CSSTransition> */}
            <button type="button" onClick={signOut} className="p-link p-mt-3">
                <i className="pi pi-fw pi-sign-out p-mr-2" /><span>Sair</span>
            </button>
        </div>
    );

}
