import { useState, createContext } from 'react';
import 'primereact/resources/themes/saga-green/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import apiKeycloak from '../api/apiKeycloak';
import queryString from 'querystring';
import jwt from 'jwt-decode';
// import { campanhaService } from '../services/campanhaService';

export const AuthContext = createContext({});

function AuthProvider({ children }) {

  const [user, setUser] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loading, setLoading] = useState(false);
  //   const [campanhaAtiva, setCampanhaAtiva] = useState(null);

  //Fazendo login do usuario
  async function signIn(email, password) {
    setLoadingAuth(true);

    //usando keycloak para login
    const data = await auth_keycloak(email, password);
    // const campanhaTemp = await campanhaService.findByAtivo();

    // setCampanhaAtiva(campanhaTemp);    

    if (data !== null) {
      setUser(data);
      setLoadingAuth(false);
    } else {
      setLoadingAuth(false);
    }

    return data
  }

  async function auth_keycloak(email, password) {

    let dataResponse = null;
    let tokenTemp = null;

    // Form de dados para obtenção do acess token
    //OBS: IMPLEMENTAR O CÓDIGO ABAIXO EM UMA FUNÇÃO...
    const form = {
      "client_id": process.env.REACT_APP_API_KEYCLOAK_CLIENT_ID,
      "username": email,
      "password": password,
      "grant_type": "password",
      "client_secret": process.env.REACT_APP_API_KEYCLOAK_CLIENT_SECRET,
      "scope": "openid"
    };

    // OBtendo o access token do cliente keycloak
    await apiKeycloak.post(process.env.REACT_APP_API_KEYCLOAK_TOKEN_CLIENT, queryString.stringify(form),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
      .then((res) => {
        tokenTemp = res.data;
      })
      .catch((error) => {
        console.log(error + " DEU RUIM ao obter o token............")
        return error;
      });

    //Obtendo as informações do usuário do keycloak caso o token seja válido... 
    if (tokenTemp !== null) {
      await apiKeycloak.post(process.env.REACT_APP_API_KEYCLOAK_USERINFO,
        {}, {
        headers: { "Authorization": `Bearer ${tokenTemp.access_token}` }
      })
        .then((response) => {
          dataResponse = {
            uid: response.data.sub,
            nome: response.data.name,
            avatarUrl: response.data.avatarUrl,
            email: response.data.email,
            tokenInfo: tokenTemp,
            perfilUser: jwt(tokenTemp.access_token).realm_access.roles
          }
        })
        .catch((error) => {
          console.log(error.error + " DEU RUIM............")
          return error;
        });
    }

    return dataResponse;
  }

  //Cadastrando um novo usuario
  async function signUp(email, password, nome) {
    setLoadingAuth(false);
    setLoading(false);
    setUser(null);

    // const getToken = await get_AccessToken(email, password);

    let tokenTemp = null;

    let resultadoNome = nome.split(" ");

    // Form de dados para obtenção do token admin-cli
    //OBS: IMPLEMENTAR O CÓDIGO ABAIXO EM UMA FUNÇÃO...
    const form = {
      "client_id": "admin-cli",
      "grant_type": "client_credentials",
      "client_secret": process.env.REACT_APP_API_KEYCLOAK_ADMIN_SECRET
    };

    // OBtendo o access token do master keycloak
    await apiKeycloak.post(process.env.REACT_APP_API_KEYCLOAK_TOKEN_MASTER, queryString.stringify(form),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
      .then((res) => {
        tokenTemp = res.data;
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error + " DEU RUIM ao obter o token............")
      });

    //Criando usuário usando o token admin-cli obtido
    let credencias = [{
      'type': 'password',
      'value': password,
      'temporary': false

    }];
    const dadosUsuario = {
      "username": email,
      "firstName": resultadoNome[0],
      "lastName": nome.replace(resultadoNome[0], ""),
      "email": email,
      "enabled": "true",
      "credentials": credencias
    };

    // Teste com nova abordagem    

    return await apiKeycloak.post(process.env.REACT_APP_API_KEYCLOAK_USERS, dadosUsuario,
      {
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${tokenTemp.access_token}` }
      })
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error.response
      })

  }



  //Logout do usuario
  async function signOut() {
    //Obtendo as informações do usuário do keycloak  
    const form = {
      "client_id": process.env.REACT_APP_API_KEYCLOAK_CLIENT_ID,
      "client_secret": process.env.REACT_APP_API_KEYCLOAK_CLIENT_SECRET,
      "refresh_token": user.tokenInfo.refresh_token
    };
    try {
      await verificaToken(user);
      await apiKeycloak.post(process.env.REACT_APP_API_KEYCLOAK_LOGOUT, queryString.stringify(form),
        {
          headers: { "Authorization": `Bearer ${user.tokenInfo.access_token}` }
        })
        .then((response) => {
          localStorage.removeItem('user');
          setUser(null);
        })

    } catch (error) {
      localStorage.removeItem('user');
      setUser(null);
      console.log(error + " DEU RUIM AO SAIR...........")
    }
  }

  //Verifica se o token é valido...
  // async function verificaToken() {

  //   const agora = (Date.now() / 1000).toFixed(0);
  //   const exp = jwt(user.tokenInfo.access_token).exp;
  //   const tempUser = user;

  //   // Verifica validade do token...
  //   if (agora > exp - 10) {
  //     console.log("INICIA RENOVAÇÃO DO TOKEN....")
  //     const form = {
  //       "client_id": process.env.REACT_APP_API_KEYCLOAK_CLIENT_ID,
  //       "grant_type": "refresh_token",
  //       "client_secret": process.env.REACT_APP_API_KEYCLOAK_CLIENT_SECRET,
  //       "refresh_token": tempUser.tokenInfo.refresh_token
  //     };

  //     // OBtendo novo access token do cliente keycloak
  //     await apiKeycloak.post(process.env.REACT_APP_API_KEYCLOAK_TOKEN_CLIENT, queryString.stringify(form),
  //       {
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded"
  //         }
  //       })
  //       .then((res) => {
  //         console.log("CHAMADA API DEU CERTO...")
  //         tempUser.tokenInfo = res.data;
  //         setUser(tempUser);
  //       })
  //       .catch((error) => {
  //         setUser(null);
  //         console.log(" Erro ao renovar o token, é necessário novo Login")
  //       });
  //   }
  // }

  //Verifica se o token é valido...
  async function verificaToken(userStorage) {

    const agora = (Date.now() / 1000).toFixed(0);
    const exp = jwt(userStorage.tokenInfo.access_token).exp;
    const tempUser = userStorage;

    // Verifica validade do token...
    if (agora > exp - 10) {

      const form = {
        "client_id": process.env.REACT_APP_API_KEYCLOAK_CLIENT_ID,
        "grant_type": "refresh_token",
        "client_secret": process.env.REACT_APP_API_KEYCLOAK_CLIENT_SECRET,
        "refresh_token": tempUser.tokenInfo.refresh_token
      };

      // OBtendo novo access token do cliente keycloak
      await apiKeycloak.post(process.env.REACT_APP_API_KEYCLOAK_TOKEN_CLIENT, queryString.stringify(form),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
        .then((res) => {

          tempUser.tokenInfo = res.data;
          setUser(tempUser);
        })
        .catch((error) => {
          setUser(null);

        });
    } else {
      setUser(tempUser);
    }
  }

  //Resertar senha via email....
  async function resetPassword(email) {
    setLoadingAuth(true);
    let tokenTemp = null;
    let userTemp = null;

    // Form de dados para obtenção do token admin-cli    
    const form = {
      "client_id": "admin-cli",
      "grant_type": "client_credentials",
      "client_secret": process.env.REACT_APP_API_KEYCLOAK_ADMIN_SECRET
    };

    // OBtendo o access token do cliente keycloak
    await apiKeycloak.post(process.env.REACT_APP_API_KEYCLOAK_TOKEN_MASTER, queryString.stringify(form),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
      .then((response) => {
        tokenTemp = response.data;
      })
      .catch((error) => {
        console.log(error + " DEU RUIM ao obter o token............")
      });

    //fazendo a chamada api  para obtenção do usuário de acordo com o email

    await apiKeycloak.get(process.env.REACT_APP_API_KEYCLOAK_USERS + '?email=' + email,
      { headers: { "Authorization": `Bearer ${tokenTemp.access_token}` } },
    )
      .then((response) => {
        userTemp = response.data;
      })
      .catch((error) => {
        console.log(error.error + " DEU RUIM AO OBTER USUÁRIO............")
      });

    // Fazendo a chamada api para disparo do envio de email para reset da senha
    //Verifica se houve retorno de um usuário
    if (userTemp.length === 1) {
      return await apiKeycloak.put(process.env.REACT_APP_API_KEYCLOAK_USERS + userTemp[0].id + '/execute-actions-email', '["UPDATE_PASSWORD"]',
        {
          headers: { "Content-Type": "application/json", "Authorization": `Bearer ${tokenTemp.access_token}` }
        })
        .then((response) => {
          setLoadingAuth(false);
          return response
        })
        .catch((error) => {
          setLoadingAuth(false);
          return error.response
        });
    } else {
      setLoadingAuth(false);
      return { status: 409 }
    }

  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        loading,
        // campanhaAtiva,
        signUp,
        signOut,
        signIn,
        loadingAuth,
        setUser,
        verificaToken,
        resetPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider;
